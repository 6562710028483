.menu {
    display: block;
    padding: 50px 0 0 0;
}

.menu a {
    color: white;
    font-size: x-large;
    text-decoration: none;
    margin: 50px 0 0 50px;
}

.menu a:hover {
    text-decoration: underline;
}

.container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    height: 100vh;
    width: 100%;
}

.headercontent {
    display: grid;
    color: white;
    grid-template-columns: 1fr 1fr;
    margin: 50px 0 0 0;
    grid-template-rows: auto;
    align-items: center;
    justify-items: center;
}

.headercontent h1 {
    font-size: 52px;
    line-height: 200%;
    font-weight: normal;
    width: 70%;
}

.image {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center; */
}
.image img {
    position: absolute;
    top: 250px;
    left: 100px;
}

@media screen and (min-width: 1700px) {
    .container {
        height: 120vh;
    }
}