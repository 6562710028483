.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.first {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  column-gap: 8em;
  margin: 0 auto;
}

.first img {
    margin: 50px;
  }

.title {
  font-weight: normal;
  display: grid;
  justify-items: start;
  margin: 100px 20px 20px 100px;
}
