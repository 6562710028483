.main {
  background-image: url('../assets/services.png');
  background-size: cover;
  height: 100vh;
  margin-top: 100px;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 300px;
}
.title {
  font-weight: normal;
  display: grid;
  color: white;
  justify-items: start;
  margin: 50px 20px 20px 100px;
  padding-top: 80px;
}
.section > h2 {
  color: white;
  font-weight: normal;
}
.section > img {
  margin: 20px;
}
.firstdiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 8em;
  align-items: center;
  margin: 0 auto;
}

@media screen and (min-width: 1440px) {
    .main {
        height: 120vh;
    }
    .container {
        margin-top: 200px;
    }
}
