.container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.container h2 {
    width: 80%;
    font-weight: normal;
    line-height: 150%;
}

.links {
    display: inline;
}

.links img {
    padding: 20px;
    cursor: pointer;
}